import { useEffect, useState } from "react";

const INTERVAL_TIMING = 150;

const items = [
  "M8.49984 4.25V2.25",
  "M11.3335 5.41661L12.7668 3.98328",
  "M12.5 8.24996H14.5",
  "M11.3335 11.0834L12.7668 12.5167",
  "M8.49984 12.25V14.25",
  "M5.66673 11.0834L4.2334 12.5167",
  "M4.5 8.24996H2.5",
  "M5.66673 5.41661L4.2334 3.98328",
];

const WHITE = "#FFF";
const DARK_GRAY = "#A9A9A9";
const PRIMARY = "#7367F0";
const LIGHT_GRAY = "#DBDADE";

interface ToggleLoaderProps {
  checked: boolean;
}

export const ToggleLoader = ({ checked }: ToggleLoaderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [primary, secondary] = [checked ? WHITE : PRIMARY, checked ? DARK_GRAY : LIGHT_GRAY];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex > 6) {
          return 0;
        }

        return prevIndex + 1;
      });
    }, INTERVAL_TIMING);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {items.map((d, index) => (
        <path
          key={d}
          d={d}
          stroke={currentIndex === index ? primary : secondary}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ))}
    </svg>
  );
};
