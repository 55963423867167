import { Breadcrumbs } from "@components/breadCrumbs";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser.ts";
import { useRetailByIdQuery } from "@shared/services/retails";
import { Box, Meta, Tabs } from "@shared/ui";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

import { useAnalyticsItems } from "./layout.hook.tsx";

export const Layout = () => {
  const { t } = useTranslation();
  const { retailId } = useParams();

  const isDirector = useCurrentRole(AppRole.DIRECTOR);

  const { items } = useAnalyticsItems(retailId);
  const { data } = useRetailByIdQuery({ retailId: Number(retailId) });

  const ignoredSegments = [AppRoutes.GENERAL_INFO];

  return (
    <Meta title={t("page-titles.analytics")}>
      <Box gapY="md">
        {!isDirector && (
          <Breadcrumbs
            ignoredSegments={ignoredSegments}
            lastSegmentName={data?.retail_network_name}
          />
        )}
        <Box direction="row" alignItems="center" justifyContent="between">
          <Tabs items={items} />
        </Box>
        <Outlet />
      </Box>
    </Meta>
  );
};
