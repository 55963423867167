import { Breadcrumbs } from "@components/breadCrumbs";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { BreadcrumbPrefix } from "@shared/constants/breadcrumbs.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser.ts";
import { useRetailBannerQuery } from "@shared/services/retail";
import { Box, Tabs } from "@shared/ui";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { RetailCardInformation } from "./components/retailCardInformation";
import { useRetailLayout } from "./layout.hook.tsx";
import css from "./layout.module.scss";

export const Layout = () => {
  const { retailId } = useParams();
  const { pathname } = useLocation();
  const isDirector = useCurrentRole(AppRole.DIRECTOR);

  const { isLoading, data } = useRetailBannerQuery(Number(retailId));
  const { items } = useRetailLayout({ retailId, isDirector });

  const ignoredSegments = isDirector ? [AppRoutes.RETAILS] : undefined;
  const isEmployeeStatPage = pathname.includes(AppRoutes.EMPLOYEE_STATISTICS);

  return (
    <Box className={css.container}>
      <Breadcrumbs ignoredSegments={ignoredSegments} prefix={BreadcrumbPrefix.RETAIL} />
      <Tabs items={items} />
      {!isEmployeeStatPage && (
        <RetailCardInformation retailId={Number(retailId)} employee={data} isLoading={isLoading} />
      )}
      <Outlet context={[data?.director_id, data?.name]} />
    </Box>
  );
};
