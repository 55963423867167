import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSlice } from "@shared/lib/hooks/useSlice.ts";
import { BannerInfo } from "@shared/services/retails";

import { InitialState } from "./retail.types";

const initialState: InitialState = {};

const retailSlice = createSlice({
  name: "retail",
  initialState,
  reducers: {
    setRetailId: (state, user: PayloadAction<number>) => {
      state.retailId = user.payload;
    },
    setRetail: (state, retail: PayloadAction<BannerInfo>) => {
      state.retail = retail.payload;
    },
  },
});

export const { actions: retailActions, reducer: retailReducer } = retailSlice;

export const useRetailActions = () => useSlice(retailSlice);
export * from "./retail.hook.ts";
