import EmployeeStatisticsIcon from "@shared/assets/icons/pageList/employeeStatistic.svg";
import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import EmployeesIcon from "@shared/assets/icons/pageList/persons.svg";
import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { AppRole } from "@shared/constants/appRoles.constant";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser";
import { useTranslation } from "react-i18next";

interface ShopItemsProps {
  retailId?: number | string;
  shopId?: number | string;
}

export const useShopItems = ({ retailId, shopId }: ShopItemsProps) => {
  const { t } = useTranslation();
  const isDirector = useCurrentRole(AppRole.DIRECTOR);

  const items = [
    {
      title: t("page-titles.shop.statistic"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.RETAIL_SHOPS}/${shopId}/${AppRoutes.STATISTIC}`,
      icon: <StatisticsIcon />,
    },
    {
      title: t("page-titles.shop.employees"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.RETAIL_SHOPS}/${shopId}/${AppRoutes.EMPLOYEES}`,
      icon: <EmployeesIcon />,
    },
    {
      title: t("page-titles.shop.employees-statistics"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.RETAIL_SHOPS}/${shopId}/${AppRoutes.EMPLOYEE_STATISTICS}`,
      icon: <EmployeeStatisticsIcon />,
    },
  ];

  if (!isDirector) {
    items.push({
      title: t("page-titles.shop.equipment"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.RETAIL_SHOPS}/${shopId}/${AppRoutes.EQUIPMENT}`,
      icon: <EquipmentIcon />,
    });
  }

  return {
    items,
  };
};
