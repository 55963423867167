export enum AppRoutes {
  HOME = "/",
  PROFILE = "/profile",
  DOCUMENTATION = "/documentation",
  ADVERTISING = "/advertising",
  RETAILS = "/retails",
  REQUESTS = "/requests/",
  SHOPS = "/retails/:retailId/shops",
  SHOP = "/retails/:retailId/shops/:shopId/statistic",
  SHOP_STATISTIC = ":shopId/statistic",
  RETAIL_DETAIL = "retails/:retailId",
  RETAIL_SHOPS = "shops",
  EQUIPMENTS = "equipments",
  EQUIPMENT = "equipment",
  STATISTICS = "statistics",
  EMPLOYEE_STATISTICS = "employee-statistics",
  STATISTIC = "statistic",
  RETAIL_DIRECTOR = "retails/:retailId/shops",
  EMPLOYEES = "employees",
  DETAIL_SHOPS = "retails/:retailId/shops/:shopId",
  DETAIL_SHOPS_EQUIPMENT = "retails/:retailId/shops/:shopId/equipment",
  EMPLOYEE_RETAIL = "/retails/:retailId/employees/:id",
  EMPLOYEE_SHOP = "/retails/:retailId/shops/:shopId/employees/:id",
  EMPLOYEE_RETAIL_STATISTICS = "/retails/:retailId/employee-statistics/:id",
  EMPLOYEE_SHOP_STATISTICS = "/retails/:retailId/shops/:shopId/employee-statistics/:id",
  FINANCE = "/finance/",
  ANALYTICS = "/analytics",
  DETAIL_ANALYTICS = "/analytics/:retailId",
  ANALYTICS_GENERAL_INFORMATION = "/analytics/:retailId/general-information",
  GENERAL_INFO = "/general-information",
  BILL_DETAIL = "bills/:billId",
  FINANCE_BILLS = "/finance/bills",
  ROLES = "/roles",
  UBO = "/ubo",
  UBO_PROFILE = "/ubo/profiles-ubo/:profileId",
  UBO_PROFILES = "/ubo/profiles-ubo",
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:id",
  FINANCE_STATISTIC = "statistic-finance",
  CONVERSION = "conversion",
  BILLS = "bills",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  SERVER_ERROR = "/503",
  CLIENT_ERROR = "/404",
  OTHER = "*",
}
