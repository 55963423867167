import * as Switch from "@radix-ui/react-switch";
import { SwitchProps } from "@radix-ui/react-switch";
import cn from "classnames";
import { forwardRef, ReactNode } from "react";

import { ToggleLoader } from "./Toggle.loader.tsx";
import css from "./Toggle.module.scss";

interface ToggleProps extends SwitchProps {
  children?: ReactNode;
  isLoading?: boolean;
}

const ToggleComponent = forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, checked, isLoading, ...props }, ref) => {
    return (
      <div className={css.wrapper}>
        <label className={css.label}>{children}</label>
        <Switch.Root
          className={cn(css.root, { [css.loadingThumb]: isLoading })}
          checked={checked}
          {...props}
        >
          {isLoading && <ToggleLoader checked={Boolean(checked)} />}
          {!isLoading && (
            <Switch.Thumb
              role="switch"
              aria-checked={checked ? "true" : "false"}
              aria-label="switch"
              className={css.thumb}
              ref={ref}
            />
          )}
        </Switch.Root>
      </div>
    );
  },
);

export default ToggleComponent;
