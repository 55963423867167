export enum ApiUrl {
  LOGIN = "/admin/token",
  REFRESH = "/token/refresh",
  ADMIN = "/admin/",
  REGISTER_USER = "/admin/register-user",
  CHANGE_PASSWORD = "/admin/change-password",
  USER_ME = "/users/me",
  USERS = "admin/users",
  USER_INFO = "/users/:id/info",
  STAFF_INFO_BY_ID = "/staff/:id/info",
  STAFF_CONSULTATION_STATISTIC = "/staff/consultation/:id/statistics",
  STAFF_CONSULTATION_HISTORY = "/staff/:id/consultations",
  REGISTER_CONSULTANT = "/users/register/consultant",
  REGISTER_MANAGER = "/users/register/manager",
  REQUESTS = "/requests/",
  CREATE_REQUESTS = "/requests/:type",
  REQUESTS_BY_ID = "/requests/:type/:id",
  RETAIL = "/retail-networks/",
  RETAIL_BY_ID = "/retail-networks/:id/",
  RETAIL_SHOPS = "/retail-networks/:id/shops",
  RETAIL_EQUIPMENT = "/retail-networks/:id/equipment",
  RETAIL_BANNER = "/retail-networks/:id/banner",
  RETAIL_EMPLOYEES_BY_COUNT = "/retail-networks/:id/staff/count-by-role",
  SHOPS_EQUIPMENT = "/shops/:id/equipment",
  SHOP_INFO = "/shops/:id/info",
  SHOP_ADD = "/shops/add",
  SHOP_UPDATE = "/shops/:id/edit",
  SHOP_BANNER = "/shops/:id/banner",
  EQUIPMENT = "/equipment/",
  EQUIPMENT_BY_ID = "/equipment/:id/",
  EQUIPMENT_STATUS_HISTORY = "/equipment/:id/status-changes/",
  STATISTICS = "/statistics",
  RETAIL_STATISTICS = "/statistics/retail-networks/:id",
  RETAIL_STATISTICS_CHART = "/statistics/retail-networks/:id/chart",
  SHOP_STATISTICS = "/shops/:id/statistics",
  SHOP_STATISTICS_CHART = "/statistics/shops/:id/chart",
  SHOP_STATISTICS_SANKEY = "/statistics/shops/:id/sankey",
  CONSULTANT_STATISTICS = "/statistics/consultants/:id",
  SHOP_STAFF = "/shops/:id/staff",
  RETAIL_STAFF = "/retail-networks/:id/staff",
  VISITS = "/shops/:id/visits",
  STAFF_SHOPS = "/staff/:id/shops",
  EMPLOYEE_STATISTICS_SHOP = "/statistics/consultants",
  RESET_PASSWORD = "/admin/reset-password",
  DOCUMENTS = "/documents",
  PRIVACY_POLICY = "/documents/:country/policy",
  CUSTOMERS_SUMMARY = "/customers/summary",
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:id/detail-info",
  CUSTOMER_STATISTICS = "/customers/:id/statistics",
  CUSTOMER_VISITS = "/customers/:id/visits",
  CUSTOMER_RETAIL_VISITS = "/customers/:id/visited-shops",
  EXPORT_STATISTICS = "/statistics/retail-network/shops/report",
  GET_ANALYTICS_SUMMARY = "/statistics/retail-networks/shops/summary",
  GET_ANALYTICS_CHART = "/statistics/retail-networks/shops/chart",
  GET_ANALYTICS_SHOPS = "/statistics/retail-networks/shops",
  UPDATE_NOTIFICATIONS_STATUS = "/users/me/notifications/:id/",
  GET_NOTIFICATIONS = "/users/me/notifications",
  CREATE_FSM_TOKEN = "/users/me/fcm-token/",
  UPDATE_FSM_TOKEN = "/users/me/fcm-token/:tokenId/",
  DELETE_FSM_TOKEN = "/users/me/fcm-token/:id",
}
