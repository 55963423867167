import RatingIcon from "@shared/assets/icons/rating.svg";
import { useRandomColor } from "@shared/utils/color/randomColor.ts";
import cn from "classnames";
import { memo } from "react";

import { Box } from "../../../box";
import { EntityAvatar } from "../../../entityAvatar";
import { OverflowTooltip } from "../../../tooltip";
import { Typography } from "../../../typography";
import css from "./TableAvatar.module.scss";
import { TableAvatarProps } from "./TableAvatar.types.ts";

export const TableAvatarContent = memo(
  ({
    name,
    id,
    surname,
    logo,
    title,
    size,
    description,
    rating,
    type = "default",
    hasBackground = true,
    isPrimaryDescription,
    isPerson,
    isContain,
    isCircle = true,
  }: TableAvatarProps) => {
    const styles = useRandomColor({
      id: Number(id),
      options: { size: "2.5px", type, hasBackground },
    });

    return (
      <>
        <Box
          alignItems="center"
          justifyContent="center"
          style={styles}
          className={cn(css.wrapper, { [css.imageWrapperCircle]: isCircle })}
        >
          <EntityAvatar
            name={name}
            surname={surname}
            companyName={title}
            logo={logo}
            size={size}
            isContain={isContain}
            isPerson={isPerson}
            isCircle={isCircle}
          />
        </Box>
        <Box justifyContent="center" className={css.titlesContainer} truncate>
          <OverflowTooltip content={title}>
            <Typography variant="body2bold" fontWeight="bold" className={css.title} truncate>
              {title}
            </Typography>
          </OverflowTooltip>
          <OverflowTooltip content={description}>
            <Typography
              variant="body4"
              color={isPrimaryDescription ? "purple" : "secondary"}
              className={css.subTitle}
              truncate
            >
              {description}
            </Typography>
          </OverflowTooltip>
        </Box>
        {rating && (
          <Box alignItems="center" direction="row" justifyContent="center" className="gap-1">
            <RatingIcon />
            <span className={css.rating}>{rating}</span>
          </Box>
        )}
      </>
    );
  },
);
