export const getIOSDevice = () => {
  const userAgent = navigator.userAgent || "";
  const isTouchDevice = navigator.maxTouchPoints > 1;

  const isIOSUserAgent = /iPad|iPhone|iPod/.test(userAgent);

  const isIPadInDesktopMode = isTouchDevice && /Macintosh/.test(userAgent);

  return isIOSUserAgent || isIPadInDesktopMode;
};
