import { api } from "@shared/configs/api.config.ts";
import { ApiUrl } from "@shared/constants/apiUrl.constant.ts";
import { PaginationQueryParams } from "@shared/types/global.interface.ts";
import { parseObjToQueryParams } from "@shared/utils/parseObjToQueryParams.ts";

import {
  DeleteNotificationsStatusArgs,
  FcmTokenArgs,
  NotificationResponseWithPagination,
  UpdateNotificationsStatusArgs,
} from "./notifications.types.ts";

export const NotificationsService = {
  async deleteNotifications(data?: DeleteNotificationsStatusArgs) {
    const params = parseObjToQueryParams(data || { ids: [] });
    return api.delete(ApiUrl.GET_NOTIFICATIONS, { params });
  },

  async updateNotificationStatus({ notifId }: UpdateNotificationsStatusArgs) {
    return api.patch(ApiUrl.UPDATE_NOTIFICATIONS_STATUS.replace(":id", notifId), { is_read: true });
  },

  async createFcmToken({ tokenId, language }: FcmTokenArgs) {
    return api.post(ApiUrl.CREATE_FSM_TOKEN, {
      fcm_token: tokenId,
      language,
    });
  },

  async updateFcmToken({ tokenId, language }: FcmTokenArgs) {
    return api.patch(ApiUrl.UPDATE_FSM_TOKEN.replace(":tokenId", String(tokenId)), { language });
  },

  async deleteFcmToken({ tokenId }: FcmTokenArgs) {
    return api.delete(ApiUrl.DELETE_FSM_TOKEN.replace(":id", String(tokenId)));
  },

  async getNotifications(data: PaginationQueryParams) {
    const params = parseObjToQueryParams(data);

    return api.get<NotificationResponseWithPagination>(ApiUrl.GET_NOTIFICATIONS, { params });
  },
};
