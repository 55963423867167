import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { BreadcrumbPrefix, BreadcrumbsConstant } from "@shared/constants/breadcrumbs.constant.ts";
import { Box } from "@shared/ui";
import { shouldIgnoreSegment } from "@shared/utils/shouldIgnoreSegment.ts";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import css from "./BreadCrumbs.module.scss";

interface BreadcrumbsProps {
  prefix?: BreadcrumbPrefix;
  ignoredSegments?: AppRoutes[];
  lastSegmentName?: string;
  idIgnored?: boolean;
}

type BreadcrumbsType = keyof typeof BreadcrumbsConstant;

const Breadcrumbs = ({
  prefix = BreadcrumbPrefix.DEFAULT,
  lastSegmentName,
  ignoredSegments,
  idIgnored = true,
}: BreadcrumbsProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const segments = location.pathname.split("/").filter(Boolean);

  const displaySegments = [
    ...segments.filter((path) => !(idIgnored && /^\d+$/.test(path))),
    lastSegmentName,
  ].filter(Boolean) as string[];

  return (
    <Box direction="row" className={css.breadcrumbs}>
      {displaySegments?.map((path, index, filteredSegments) => {
        const isLastSegment = index === filteredSegments.length - 1;

        if (shouldIgnoreSegment(path, ignoredSegments)) {
          return null;
        }

        const currentPath = `/${segments.slice(0, segments.indexOf(path) + 1).join("/")}`;
        const pathWithPrefix = BreadcrumbsConstant[`${prefix}${path}` as BreadcrumbsType];

        const str =
          isLastSegment && lastSegmentName
            ? lastSegmentName
            : decodeURIComponent(t(pathWithPrefix));

        return (
          <Box direction="row" key={index} className={css.crumb}>
            <Link to={currentPath}>{str}</Link>
            {!isLastSegment && <span className={css.separator}>/</span>}
          </Box>
        );
      })}
    </Box>
  );
};
export default Breadcrumbs;
