export const BreadcrumbsConstant = {
  retail_employees: "page-titles.retail.employees",
  retail_shops: "page-titles.retail.shops",
  "retail_employee-statistics": "page-titles.retail.employees-statistics",
  retail_equipments: "page-titles.retail.equipment",
  retail_retails: "page-titles.retails",
  shop_employees: "page-titles.shop.employees",
  shop_statistic: "page-titles.shop.statistic",
  shop_shops: "page-titles.retail.shops",
  "shop_employee-statistics": "page-titles.shop.employees-statistics",
  shop_equipment: "page-titles.shop.equipment",
  shop_retails: "page-titles.retails",
  shops: "page-titles.retail.shops",
  employees: "page-titles.shop.employees",
  customers: "page-titles.customers",
  analytics: "page-titles.analytics",
  retails: "page-titles.retails",
} as const;

export enum BreadcrumbPrefix {
  RETAIL = "retail_",
  SHOP = "shop_",
  DEFAULT = "",
}
