export enum StatusRequest {
  new = "new",
  processing = "processing",
  approved = "approved",
  rejected = "rejected",
}

export enum StatusRetail {
  connected = "connected",
  disconnected = "disconnected",
  on_service = "on_service",
  archived = "archived",
  pending = "pending",
}

export enum StatusShop {
  connected = "connected",
  disconnected = "disconnected",
  on_service = "on_service",
  archived = "archived",
  pending = "pending",
}

export enum StatusUser {
  working = "working",
  not_working = "not_working",
  vacationing = "vacationing",
  on_break = "on_break",
  archived = "archived",
  blocked = "blocked",
}

export enum StatusEquipment {
  working = "working",
  not_working = "not_working",
  archived = "archived",
}

export enum StatusCustomer {
  active = "active",
  inactive = "inactive",
  removed = "removed",
  returned = "returned",
}

export enum TitleType {
  Camera = "camera",
  Tablet = "tablet",
  Software = "software",
  Server = "server",
  CashRegister = "cash_register",
  Other = "other",
}

export enum TypeEquipments {
  camera = "camera",
  tablet = "tablet",
  server = "server",
  software = "software",
  cashRegister = "cash_register",
  other = "other",
}

export enum EmployeeStatisticsOptions {
  CONSULTATIONS = "consultations",
  UBO_CARDS = "consult_ubocards",
  PURCHASES = "consult_purchases",
  REVENUE = "consult_revenue",
}

export enum RequestsType {
  RETAIL_CONNECTION = "NC",
  SHOP_CONNECTION = "SC",
  RETAIL_DISCONNECTION = "ND",
  SHOP_DISCONNECTION = "SD",
}

export interface StatusControl {
  count: number;
  type: string[];
  status: string | number;
}

export type StatusVariant =
  | StatusRetail
  | StatusEquipment
  | StatusShop
  | StatusUser
  | StatusRequest
  | StatusCustomer;
