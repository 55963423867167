import AvatarNotFoundIcon from "@shared/assets/icons/avatar/avatarNotFound.svg";
import { useRandomColor } from "@shared/utils/color/randomColor.ts";
import cn from "classnames";

import { Box } from "../box";
import { Image } from "../image";
import { Typography, Variants } from "../typography";
import css from "./EntityAvatar.module.scss";

interface EntityAvatarProps {
  id?: number;
  name?: string;
  surname?: string;
  companyName?: string;
  logo?: string | null;
  size?: Variants;
  className?: string;
  type?: "withBorder" | "default";
  hasBackground?: boolean;
  isHeaderAvatar?: boolean;
  isContain?: boolean;
  isBorder?: boolean;
  isPerson?: boolean;
  isCircle?: boolean;
}

export const EntityAvatar = ({
  id,
  name,
  surname,
  companyName,
  logo,
  size,
  className,
  type = "default",
  isHeaderAvatar,
  isContain,
  isBorder,
  isCircle = true,
  isPerson,
  hasBackground,
}: EntityAvatarProps) => {
  const { border } = useRandomColor({
    id: Number(id),
    options: { size: "3px", hasBackground, type },
  });

  if (isHeaderAvatar && !logo) {
    return <AvatarNotFoundIcon className="size-full" />;
  }
  const containClass = isContain && isPerson ? "imageContainPersons" : "imageContain";
  const isHuman = !logo && (name || surname);
  const isRetail = !logo && !(name || surname) && companyName;

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      style={{ border }}
      className={isBorder ? css.wrapperWithBorder : css.wrapper}
    >
      {logo && (
        <Image
          src={logo}
          alt="IconLogo"
          className={cn(css.image, isContain ? css[containClass] : "object-cover", className, {
            [css.circleImage]: isCircle,
          })}
        />
      )}
      {isHuman && (
        <Typography variant={size} fontWeight="normal" uppercase>
          {name?.[0]}
          {surname?.[0]}
        </Typography>
      )}
      {isRetail && (
        <Typography variant={size} fontWeight="normal" uppercase>
          {companyName?.[0]}
          {companyName?.[1]}
        </Typography>
      )}
    </Box>
  );
};
