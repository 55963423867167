import { AppCookie } from "@shared/constants/appCookies.constant.ts";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { QueryTag } from "@shared/constants/queryTags.constants.ts";
import { useIntercomSession } from "@shared/lib/hooks/useIntercomSession.ts";
import { useRetailActions } from "@shared/store/retail";
import { useUserActions } from "@shared/store/user";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { decodeJwtToken } from "@shared/utils/decodeJwt.ts";
import { parseError } from "@shared/utils/parseError.ts";
import { removeTokensFromCookieStorage } from "@shared/utils/token.ts";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AuthService } from "./auth.services.ts";

export const useUserMeQuery = () => {
  const { t } = useTranslation();

  const { setUser } = useUserActions();
  const { setRetailId } = useRetailActions();
  const { onConnect } = useIntercomSession();

  const { data, isSuccess, error, ...query } = useQuery({
    queryKey: [QueryTag.GET_USER_ME],
    queryFn: async () => AuthService.userMe(),
    enabled: false,
  });

  useEffect(() => {
    if (isSuccess) {
      if (data.role === AppRole.DIRECTOR) {
        const accessTokenFromCookie = Cookies.get(AppCookie.ACCESS);
        const accessTokenFromSession = sessionStorage.getItem(AppCookie.ACCESS);
        const accessToken = accessTokenFromCookie || accessTokenFromSession;

        const director = decodeJwtToken(accessToken as string);

        setUser(data);
        onConnect(data);
        setRetailId(director.retail_network_id);
      } else {
        setUser(data);
      }
    }
  }, [data, isSuccess, onConnect, setRetailId, setUser]);

  useEffect(() => {
    if (error) {
      parseError(error, {
        handleCustomMessage: () => showToast(t("toast.error.user"), CustomToastVariants.ERROR),
      });
      removeTokensFromCookieStorage();
    }
  }, [error, t]);

  return { ...query, isSuccess, data };
};
