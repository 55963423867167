import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { createModalHook } from "@shared/ui";
import { lazy } from "react";

import { ShopEditProps } from "./edit/ShopEdit.modal.tsx";

const ShopEdit = withSuspense(lazy(() => import("./edit/ShopEdit.modal.tsx")));

export const useEditShopModal = createModalHook<ShopEditProps>((props) => () => (
  <ShopEdit {...props} />
));
