import { api } from "@shared/configs/api.config.ts";
import { ApiUrl } from "@shared/constants/apiUrl.constant.ts";
import { BannerInfo } from "@shared/services/retails";
import { ResponseWithPagination } from "@shared/types/global.interface.ts";
import { Visits } from "@shared/types/visits.interface.ts";
import { parseObjToQueryParams } from "@shared/utils/parseObjToQueryParams.ts";

import { GetVisitsArgs } from "./shop.types";

export const ShopService = {
  async getShopBannerInfo(shopId: number) {
    return api.get<BannerInfo>(ApiUrl.SHOP_BANNER.replace(":id", String(shopId)));
  },

  async getShopVisits({ id, ...props }: GetVisitsArgs) {
    const params = parseObjToQueryParams(props);

    return api.get<ResponseWithPagination<Visits[]>>(ApiUrl.VISITS.replace(":id", String(id)), {
      params,
    });
  },
};
