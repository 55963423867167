import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSlice } from "@shared/lib/hooks/useSlice.ts";
import { User } from "@shared/services/users";

import { InitialState } from "./user.types.ts";

const initialState: InitialState = {
  user: {} as User,
  notifCount: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    incNotif: (state) => {
      state.notifCount++;
    },
    decNotif: (state) => {
      state.notifCount--;
    },
    setNotifCount: (state, payload: PayloadAction<{ count: number }>) => {
      state.notifCount = payload.payload.count;
    },
    setUser: (state, user: PayloadAction<User | undefined>) => {
      if (user.payload) {
        state.user = user.payload;
      }
    },
    setUserNotifStatus: (state, user: PayloadAction<boolean | undefined>) => {
      state.user.is_notifications_on = user.payload;
    },
    clearUser: (state) => {
      state.user = {} as User;
    },
    clearNotif: (state) => {
      state.notifCount = 0;
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;

export const useUserActions = () => useSlice(userSlice);
