import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { createModalHook } from "@shared/ui";
import { lazy } from "react";

import { RetailEditProps } from "./RetailEdit.modal.tsx";

const RetailEdit = withSuspense(lazy(() => import("./RetailEdit.modal.tsx")));

export const useEditRetailModal = createModalHook<RetailEditProps>((props) => () => (
  <RetailEdit {...props} />
));
