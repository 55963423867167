import { CardInformation, CardInformationProps } from "@components/cardInformation";
import { useEditShopModal } from "@components/modals/shops";
import { FirebaseEvent } from "@shared/constants/firebase/firebaseEvent.constant.ts";
import { useFirebase } from "@shared/lib/hooks/useFirebase.ts";
import { useTranslation } from "react-i18next";

interface ShopCardInformationProps extends Omit<CardInformationProps, "onEdit" | "type"> {
  shopId: number;
  retailId: number;
}

const ShopCardInformationComponent = ({
  shopId,
  retailId,
  employee,
  ...props
}: ShopCardInformationProps) => {
  const { t } = useTranslation();
  const { logEventToFirebase } = useFirebase();
  const [openEditModal] = useEditShopModal();

  const onEdit = () => {
    openEditModal({
      directorId: Number(employee?.director_id),
      retailId,
      shopId,
      title: t("modals.edit.shop", { number: shopId }),
    });
    logEventToFirebase(FirebaseEvent.RETAILS_EDIT);
  };
  return (
    <CardInformation
      onEdit={onEdit}
      type="shop"
      employee={employee}
      shopId={shopId}
      retailId={retailId}
      {...props}
    />
  );
};

export default ShopCardInformationComponent;
