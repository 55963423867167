import { AppRoutes } from "@shared/constants/appRoutes.constant";

import { RequestEventType } from "./notifications.types";

interface RouteParams {
  retailId: string;
  shopId: string;
}

export const TypeToRouteMapper = {
  [RequestEventType.REQUESTS_RETAIL_CONNECTION]: () => AppRoutes.REQUESTS,
  [RequestEventType.REQUESTS_RETAIL_DISCONNECTION]: () => AppRoutes.REQUESTS,
  [RequestEventType.REQUESTS_SHOP_CONNECTION]: () => AppRoutes.REQUESTS,
  [RequestEventType.REQUESTS_SHOP_DISCONNECTION]: () => AppRoutes.REQUESTS,
  [RequestEventType.REQUEST_STATUS_CHANGING]: () => AppRoutes.REQUESTS,
  [RequestEventType.INTERNAL_EQUIPMENT_STATUS_CHANGING]: ({ retailId, shopId }: RouteParams) =>
    AppRoutes.DETAIL_SHOPS_EQUIPMENT.replace(":retailId", retailId).replace(":shopId", shopId),
  [RequestEventType.CLIENT_EQUIPMENTS_STATUS_CHANGING]: ({ retailId, shopId }: RouteParams) =>
    AppRoutes.DETAIL_SHOPS_EQUIPMENT.replace(":retailId", retailId).replace(":shopId", shopId),
};
