import { QueryTag } from "@shared/constants/queryTags.constants.ts";
import { useLocale } from "@shared/lib/hooks/useLocale.ts";
import { PaginationQueryParams } from "@shared/types/global.interface.ts";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { parseError } from "@shared/utils/parseError.ts";
import { useInfiniteQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect } from "react";

import { NotificationsService } from "./notifications.services.ts";

interface UseGetNotificationsProps extends PaginationQueryParams {
  enabled: boolean;
  notifCount?: number;
}

export const useGetNotifications = ({ page_size = 10, enabled }: UseGetNotificationsProps) => {
  const { lang } = useLocale();

  const { data, isError, error, ...query } = useInfiniteQuery({
    queryKey: [QueryTag.GET_NOTIFICATIONS, lang],
    queryFn: ({ pageParam }) =>
      NotificationsService.getNotifications({ page: pageParam, page_size }),
    initialPageParam: 1,
    select: (data) => ({
      pages: data.pages?.map((page) => page.data?.results),
      pageParams: data?.pageParams,
      totalNewNotif: data?.pages?.[0]?.data?.count_new_notifications,
    }),
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages?.length + 1;
      return nextPage <= lastPage?.data?.total_pages ? nextPage : undefined;
    },
    enabled,
  });

  useEffect(() => {
    if (isError) {
      parseError(error, {
        handleCustomMessage: () =>
          showToast(t("toast.error.get-notifications"), CustomToastVariants.ERROR),
      });
    }
  }, [isError, error]);

  return {
    ...query,
    count: data?.totalNewNotif,
    notifications: data?.pages?.flat(),
    pagination: {
      quantityItems: data?.pages?.flat().length,
      limit: page_size,
    },
  };
};
