import { ResponseWithPagination } from "@shared/types/global.interface";

export interface UpdateNotificationsStatusArgs {
  notifId: string;
}

export interface FcmTokenArgs {
  tokenId?: string;
  language?: string;
}

export interface DeleteNotificationsStatusArgs {
  ids?: number[];
}

export enum RequestEventType {
  REQUESTS_RETAIL_CONNECTION = "INTERNAL_RTN_CONNECTION_REQUEST_CREATION",
  REQUESTS_SHOP_CONNECTION = "INTERNAL_SHOP_CONNECTION_REQUEST_CREATION",
  REQUESTS_RETAIL_DISCONNECTION = "INTERNAL_RTN_DISCONNECTION_REQUEST_CREATION",
  REQUESTS_SHOP_DISCONNECTION = "INTERNAL_SHOP_DISCONNECTION_REQUEST_CREATION",
  REQUEST_STATUS_CHANGING = "CLIENT_REQUEST_STATUS_CHANGING",
  CLIENT_EQUIPMENTS_STATUS_CHANGING = "CLIENT_EQUIPMENT_STATUS_CHANGING",
  INTERNAL_EQUIPMENT_STATUS_CHANGING = "INTERNAL_EQUIPMENT_STATUS_CHANGING",
}

export interface Notification {
  body_params: NotificationParams;
  event_type: RequestEventType;
  created_at: string;
  id: string;
  title: string;
  message: string;
  is_read: boolean;
}

interface NotificationParams {
  equipment_id: number;
  new_status: string;
  previous_status: string;
  retail_network_name: string;
  retail_network_id: number;
  shop_id: number;
  shop_name: string;
}

export interface NotificationResponseWithPagination extends ResponseWithPagination<Notification> {
  count_new_notifications: number;
}
