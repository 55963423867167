import ExportIcon from "@shared/assets/icons/actions/download.svg";
import { Box, Typography } from "@shared/ui";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useExportReportModal } from "../modals/report";
import css from "./ExportReport.module.scss";

interface ExportReportProps {
  className?: string;
  onExport?: () => void;
}

const ExportReport = ({ onExport, className }: ExportReportProps) => {
  const { t } = useTranslation();
  const { retailId, shopId } = useParams();
  const [openExportModal] = useExportReportModal();

  const onOpen = () => {
    if (onExport) {
      onExport();
    } else {
      openExportModal({
        shopId: shopId,
        retailId: Number(retailId),
        title: Boolean(shopId)
          ? t("modals.create.export-report-shop")
          : t("modals.create.export-report-retail"),
      });
    }
  };

  return (
    <Box
      direction="row"
      alignItems="center"
      className={cn(css.container, className)}
      onClick={onOpen}
    >
      <Typography variant="body3" color="purple" className={css.typography} select={false}>
        {t("report.export")}
      </Typography>
      <Box alignItems="center" justifyContent="center" className={css.svgContainer}>
        <ExportIcon className={css.svg} />
      </Box>
    </Box>
  );
};

export default ExportReport;
