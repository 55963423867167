import StatusIcon from "@shared/assets/icons/avatar/avatarStatus.svg";
import { StatusUser } from "@shared/types/statuses.interface.ts";
import cn from "classnames";

import { Box } from "../box";
import { EntityAvatar } from "../entityAvatar";
import css from "./AvatarClient.module.scss";

interface Avatar {
  image?: string | null;
  className?: string;
  type?: "withStatus" | "default";
  status?: keyof typeof StatusUser;
  onClick?: () => void;
  fullName?: string;
  isHeaderAvatar?: boolean;
}

const statusVariants = {
  [StatusUser.working]: css.green,
  [StatusUser.not_working]: css.red,
  [StatusUser.vacationing]: css.orange,
  [StatusUser.archived]: css.gray,
  [StatusUser.on_break]: css.orange,
  [StatusUser.blocked]: css.red,
};

export const AvatarClient = ({
  image,
  className,
  type = "default",
  status,
  fullName,
  isHeaderAvatar,
  onClick,
}: Avatar) => {
  const [name, surname] = fullName?.split(" ") || [];

  return (
    <Box onClick={onClick} className={cn(className, css.avatarContainer)}>
      <Box alignItems="center" justifyContent="center" className={css.imageContainer}>
        <EntityAvatar
          name={name}
          surname={surname}
          logo={image}
          className={css.img}
          isHeaderAvatar={isHeaderAvatar}
          size="body3"
        />
      </Box>
      {status && type === "withStatus" && (
        <Box className={css.status}>
          <StatusIcon className={statusVariants[status]} />
        </Box>
      )}
    </Box>
  );
};
