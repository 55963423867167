import { Breadcrumbs } from "@components/breadCrumbs";
import { ShopCardInformation } from "@pages/shop/components/shopCardInformation";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { BreadcrumbPrefix } from "@shared/constants/breadcrumbs.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser.ts";
import { useShopBannerQuery } from "@shared/services/shop";
import { Box, Tabs } from "@shared/ui";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { useShopItems } from "./layout.hook.tsx";
import css from "./layout.module.scss";

export const Layout = () => {
  const isDirector = useCurrentRole(AppRole.DIRECTOR);
  const { pathname } = useLocation();
  const { retailId, shopId } = useParams();

  const { items } = useShopItems({ retailId, shopId });
  const { data, isLoading: isLoadingBanner } = useShopBannerQuery({ id: Number(shopId) });

  const ignoredSegments = isDirector ? [AppRoutes.RETAILS] : undefined;
  const isEmployeeStatPage = pathname.includes(AppRoutes.EMPLOYEE_STATISTICS);

  return (
    <Box className={css.container}>
      <Breadcrumbs ignoredSegments={ignoredSegments} prefix={BreadcrumbPrefix.SHOP} />
      <Tabs items={items} />
      {!isEmployeeStatPage && (
        <ShopCardInformation
          shopId={Number(shopId)}
          employee={data}
          retailId={Number(retailId)}
          isLoading={isLoadingBanner}
        />
      )}
      <Outlet />
    </Box>
  );
};
