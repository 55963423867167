import EmployeeStatisticsIcon from "@shared/assets/icons/pageList/employeeStatistic.svg";
import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import EmployeesIcon from "@shared/assets/icons/pageList/persons.svg";
import ShopsIcon from "@shared/assets/icons/pageList/shops.svg";
// import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useTranslation } from "react-i18next";

interface RetailItemsProps {
  retailId?: string;
  isDirector?: boolean;
}

export const useRetailLayout = ({ retailId, isDirector = false }: RetailItemsProps) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("page-titles.retail.shops"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.RETAIL_SHOPS}`,
      icon: <ShopsIcon />,
    },
    // {
    //   title: t("statistics-shops"),
    //   link: `${AppRoutes.CLIENTS}/${clientId}/${AppRoutes.STATISTICS}`,
    //   icon: <StatisticsIcon />,
    // },
    {
      title: t("page-titles.retail.employees"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.EMPLOYEES}`,
      icon: <EmployeesIcon />,
    },
    {
      title: t("page-titles.retail.employees-statistics"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.EMPLOYEE_STATISTICS}`,
      icon: <EmployeeStatisticsIcon />,
    },
    {
      title: t("page-titles.retail.equipment"),
      link: `${AppRoutes.RETAILS}/${retailId}/${AppRoutes.EQUIPMENTS}`,
      icon: <EquipmentIcon />,
    },
  ];

  if (isDirector) {
    items.splice(items.length - 1, 1);
  }

  return {
    items,
  };
};
