import { ChangeLanguage } from "@components/changeLanguage";
import { ChangeTheme } from "@components/changeTheme";
import { Box, Typography } from "@shared/ui";
import { useTranslation } from "react-i18next";

const MobileSettings = () => {
  const { t } = useTranslation();

  return (
    <Box className="pt-4">
      <Typography color="secondary" variant="body2" className="dark:text-gray-800">
        {t("app-settings.settings")}
      </Typography>
      <ChangeTheme />
      <ChangeLanguage />
    </Box>
  );
};

export default MobileSettings;
