import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useTypedSelector } from "@shared/lib/hooks/useTypedSelector.ts";

export const useUserRole = () => useTypedSelector((state) => state.user.user?.role);

export const useCurrentRole = (role: AppRole) => {
  const userRole = useTypedSelector((state) => state.user.user?.role);
  return userRole === role;
};

export const useUserImage = () => useTypedSelector((state) => state.user.user?.image);
export const useUserStatus = () => useTypedSelector((state) => state.user.user?.status);

export const useIsAuth = () => useTypedSelector((state) => Boolean(state.user.user.id));

export const useUser = () => useTypedSelector((state) => state.user.user);
export const useNotifCount = () => useTypedSelector((state) => state.user.notifCount);

export const useBaseUrl = () =>
  useTypedSelector((state) => {
    const role = state.user.user?.role;
    const retailId = state.retail.retailId;

    if (role === AppRole.SUPERVISOR || role === AppRole.SPECIALIST) {
      return AppRoutes.REQUESTS;
    }

    if (role === AppRole.SUPPORT) {
      return AppRoutes.DOCUMENTATION;
    }

    if (role === AppRole.DIRECTOR && retailId) {
      return AppRoutes.RETAIL_DIRECTOR.replace(":retailId", String(retailId));
    }

    return AppRoutes.HOME;
  });
