import { ApiRequestType } from "@shared/constants/apiRequestsTypes.constant.ts";
import { ApiUrl } from "@shared/constants/apiUrl.constant.ts";
import { AppCookie } from "@shared/constants/appCookies.constant.ts";
import { AppStorage } from "@shared/constants/appStorage.constant.ts";
import { store } from "@shared/store";
import { userActions } from "@shared/store/user";
import { Tokens } from "@shared/store/user/user.types.ts";
import {
  removeTokensFromCookieStorage,
  saveTokensToCookieStorage,
  saveTokensToSessionStorage,
} from "@shared/utils/token.ts";
import axios from "axios";
import Cookies from "js-cookie";

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

api.interceptors.request.use((config) => {
  const accessTokenFromCookie = Cookies.get(AppCookie.ACCESS);
  const accessTokenFromSession = sessionStorage.getItem(AppCookie.ACCESS);
  const accessToken = accessTokenFromCookie || accessTokenFromSession;

  const locale = localStorage.getItem(AppStorage.LOCALE);

  if (accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (locale) {
    config.headers["Accept-Language"] = locale;
  }

  return config;
});

const getNewTokens = async () => {
  const refreshFromCookie = Cookies.get(AppCookie.REFRESH);
  const refreshFromSession = sessionStorage.getItem(AppCookie.REFRESH);
  const refresh = refreshFromCookie || refreshFromSession;

  if (refresh) {
    try {
      const response = await axios.post<Tokens>(
        ApiUrl.REFRESH,
        {
          refresh,
          source: ApiRequestType.WEB,
        },
        {
          baseURL: API_URL,
        },
      );

      if (refreshFromCookie) {
        saveTokensToCookieStorage(response.data);
      } else {
        saveTokensToSessionStorage(response.data);
      }

      return response;
    } catch (e) {
      store.dispatch(userActions.clearUser());
      removeTokensFromCookieStorage();
    }
  }
};

const TOKEN_NOT_VALID = "token_not_valid";
const UNAUTHORIZED_STATUS = 401;

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === UNAUTHORIZED_STATUS &&
      error.response.data?.errors?.[0].code === TOKEN_NOT_VALID &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      await getNewTokens();

      return api.request(originalRequest);
    }

    return Promise.reject(error);
  },
);
