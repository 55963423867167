import { api } from "@shared/configs/api.config.ts";
import { ApiUrl } from "@shared/constants/apiUrl.constant.ts";
import { BannerInfo } from "@shared/services/retails";
import { Shop } from "@shared/services/shop";
import { ResponseWithPagination } from "@shared/types/global.interface";
import { StatusShop } from "@shared/types/statuses.interface.ts";
import { parseObjToQueryParams } from "@shared/utils/parseObjToQueryParams.ts";

import { GetRetailShopsArgs } from "./retail.types";

export const RetailService = {
  async getRetailShops({ retailId, ...props }: GetRetailShopsArgs) {
    const params = parseObjToQueryParams(props);

    return api.get<ResponseWithPagination<Shop>>(
      ApiUrl.RETAIL_SHOPS.replace(":id", String(retailId)),
      {
        params,
      },
    );
  },

  async getRetailShopById(shopId: number) {
    return api.get<Shop>(ApiUrl.SHOP_INFO.replace(":id", String(shopId)));
  },

  async getShopsList(retailId: number, search?: string, isFilterStatus?: boolean) {
    const status = isFilterStatus ? [StatusShop.connected, StatusShop.on_service] : [];
    return api.get<ResponseWithPagination<Shop>>(
      ApiUrl.RETAIL_SHOPS.replace(":id", String(retailId)),
      {
        params: {
          limit: 9999,
          status,
          search,
        },
        paramsSerializer: {
          indexes: null,
        },
      },
    );
  },

  async getRetailBannerInfo(retailId: number) {
    return api.get<BannerInfo>(ApiUrl.RETAIL_BANNER.replace(":id", String(retailId)));
  },

  async createRetailShop(data: Partial<Shop>) {
    return api.post<Shop>(ApiUrl.SHOP_ADD, data);
  },

  async updateRetailShop(shopId: number, data: Partial<Shop>) {
    return api.patch<Shop>(ApiUrl.SHOP_UPDATE.replace(":id", String(shopId)), data);
  },

  async deleteRetailShop(id: number) {
    return api.patch<Shop>(ApiUrl.SHOP_UPDATE.replace(":id", String(id)), { status: "archived" });
  },
};
