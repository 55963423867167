import { AppCookie } from "@shared/constants/appCookies.constant.ts";
import { useUserMeQuery } from "@shared/services/auth";
import { useGetNotifications } from "@shared/services/notifications";
import { useUserActions } from "@shared/store/user";
import { Loader } from "@shared/ui";
import Cookies from "js-cookie";
import { PropsWithChildren, useEffect, useState } from "react";

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const { setNotifCount } = useUserActions();

  const accessTokenFromCookie = Cookies.get(AppCookie.ACCESS);
  const accessTokenFromSession = sessionStorage.getItem(AppCookie.ACCESS);
  const accessToken = accessTokenFromCookie || accessTokenFromSession;

  const { refetch } = useUserMeQuery();
  const { refetch: fetchNotifications } = useGetNotifications({ enabled: false });

  useEffect(() => {
    if (accessToken) {
      refetch().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [accessToken, refetch]);

  useEffect(() => {
    if (accessToken) {
      fetchNotifications()
        .then((response) => setNotifCount({ count: response?.data?.totalNewNotif ?? 0 }))
        .catch((err) => {
          console.log("Error fetching notifications:", err);
        });
    }
  }, [accessToken, fetchNotifications, setNotifCount]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
};
