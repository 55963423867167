import { queryClient } from "@app/providers/QueryClientProvider.tsx";
import { AppLanguage } from "@shared/constants/appLanguage.constant.ts";
import { QueryTag } from "@shared/constants/queryTags.constants.ts";
import { useLocale } from "@shared/lib/hooks/useLocale.ts";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { parseError } from "@shared/utils/parseError.ts";
import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";

import { NotificationsService } from "./notifications.services.ts";
import {
  DeleteNotificationsStatusArgs,
  FcmTokenArgs,
  UpdateNotificationsStatusArgs,
} from "./notifications.types.ts";

interface UseDeleteNotificationsArgs {
  isShowToast?: boolean;
}

export const useUpdateNotificationsStatus = () => {
  return useMutation({
    mutationFn: ({ notifId }: UpdateNotificationsStatusArgs) =>
      NotificationsService.updateNotificationStatus({ notifId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryTag.GET_NOTIFICATIONS],
      });
    },
    onError(e) {
      parseError(e, {
        handleCustomMessage: () =>
          showToast(t("toast.error.update-notifications-status"), CustomToastVariants.ERROR),
      });
    },
  });
};

export const useDeleteNotifications = (args?: UseDeleteNotificationsArgs) => {
  const { isShowToast } = args ?? {
    isShowToast: false,
  };

  return useMutation({
    mutationFn: ({ ids }: DeleteNotificationsStatusArgs) =>
      NotificationsService.deleteNotifications({ ids }),
    onSuccess: async () => {
      if (isShowToast) {
        await showToast(
          t("toast.success.update-notifications-status"),
          CustomToastVariants.SUCCESS,
        );
      }
      await queryClient.invalidateQueries({ queryKey: [QueryTag.GET_NOTIFICATIONS] });
    },
    onError(e) {
      parseError(e, {
        handleCustomMessage: () =>
          showToast(t("toast.error.update-notifications-status"), CustomToastVariants.ERROR),
      });
    },
  });
};

const FormatByLanguage: Record<AppLanguage, string> = {
  [AppLanguage.RUSSIAN]: "ru",
  [AppLanguage.ENGLISH]: "en",
};

export const useCreateFcmTokenNotifications = () => {
  const { lang } = useLocale();

  return useMutation({
    mutationFn: ({ tokenId }: FcmTokenArgs) =>
      NotificationsService.createFcmToken({
        tokenId,
        language: FormatByLanguage[lang as AppLanguage],
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryTag.GET_NOTIFICATIONS, lang] });
    },
    onError(e) {
      parseError(e, {
        handleCustomMessage: () =>
          showToast(t("toast.error.create-fcm-token"), CustomToastVariants.ERROR),
      });
    },
  });
};

export const useUpdateFcmToken = () => {
  return useMutation({
    mutationFn: ({ tokenId, language }: FcmTokenArgs) =>
      NotificationsService.updateFcmToken({
        tokenId,
        language: FormatByLanguage[language as AppLanguage],
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryTag.GET_NOTIFICATIONS] });
    },
    onError(e) {
      parseError(e, {
        handleCustomMessage: () =>
          showToast(t("toast.error.create-fcm-token"), CustomToastVariants.ERROR),
      });
    },
  });
};
